import styled from 'styled-components';
import IconCheck from '../../images/icon-check.png';

export const ParaBold = styled.b`
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #00539f;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  font-weight: normal !important;
`;
export const ParaStyle = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  text-align: center;
  code {
    font-family: 'Helvetica-Condensed', arial, sans-serif;
    font-size: 18px;
    line-height: 130%;
    color: #00539f;
  }
  a > code {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 15px 35px;
    margin-right: 10px;
    margin-bottom: 40px;
    font-family: 'MrAlex', sans-serif;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: bold;
    color: #00a6e8;
    border: 2px solid #00a6e8;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
    border-radius: 100px;
    cursor: pointer;
    text-decoration: none;
    /* code {
      font: inherit;
    } */
    @media (max-width: 1399px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    @media (max-width: 1199px) {
      font-size: 25px;
      line-height: 25px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 25px;
    }
    :hover {
      top: -5px;
      color: #f7991c;
      border-color: #f7991c;
      box-shadow: 0 5px 0 0 #f7991c;
    }
  }
`;
export const ImageStyle = styled.img`
  width: auto;
  height: 100px;
  border: 0;
  vertical-align: top;
  margin: 0 0 30px 0;

  @media (max-width: 991px) {
    height: 100px !important;
    width: auto;
    margin: 0 !important;
  }
  @media (max-width: 767px) {
    height: 100px;
    width: auto;
    margin: 0 !important;
  }
`;
export const TitleStyle = styled.h3`
  text-align: center;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #00a6e8;
  @media (max-width: 1439px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
  text-align: center;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
`;

export const HeaderH2 = styled.h2`
  text-align: center;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

export const HeaderH1 = styled.h1`
  text-align: center;
  position: relative;
  margin: 0 0 35px 0;
  font-size: 40px;
  line-height: 50px;
  font-family: 'MrAlex', arial, sans-serif;
  font-weight: normal !important;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 47px;
    padding-top: 20px;
  }
`;

export const Container = styled.div`
  text-align: center;
  width: 860px;
  margin: 0 auto;
  padding: 20px !important;
  @media (max-width: 991px) {
    padding: 30px;
    width: 100%;
  }
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
  text-align: left;
`;
export const ListItem = styled.li`
  padding: 0 0 15px 50px;
  background: url(${IconCheck}) no-repeat 0 1px;
  background-size: 30px auto;
  list-style: none;
  margin: 0;
  p {
    margin: 0;
    text-align: left;
  }
  @media (max-width: 767px) {
    padding-top: 1px;
    padding-left: 40px;
    background-position: 0 0;
    background-size: 25px auto;
  }
`;
export const HeaderH4 = styled.h4`
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const HeaderH6 = styled.h6`
  font-size: 22px;
  line-height: 28px;
  font-family: 'MrAlex', arial, sans-serif;
  color: #00539f;
  font-weight: normal;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
`;
export const Body = styled.div`
  padding: 20px;
  text-align: center;
  width: 32%;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const SubText = styled.p`
  margin: 0 0 20px 0;
  padding: 0;
  text-align: center;
  color: #00539f;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;
export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: rgba(0, 0, 0, 0.22);
  margin: 60px 0;
  clear: both;
  @media (max-width: 1399px) {
    margin: 50px 0;
  }
`;
